// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';

.navbar{
	.nav-item{
		a{
			text-decoration: none;

			&:hover{
				color: white;
			}
		}
	}
}

@media only screen and (min-width: 992px) {
	
  .navbar {
		.navbar-nav {
			.nav-item{
				.nav-link{
					padding: 0 0.5em;
				}

				&:not(:last-child) {
					.nav-link {
						border-right: 2px solid var(--bs-nav-link-color);
					}
				}
			}
		}
	}
	
}

/* if you do not remove the margin, you will see a vertical scrollbar on the .parallax div */
body {
  margin: 0;
	font-size: 18px;
	font-family: 'Roboto';

	background-image: url('/img/bg.png');
	background-position: center;
	background-repeat: repeat-y;
}

a {
	color: #FFFFFF;
}

h1{
	font-family: 'Cinzel Decorative';
	margin-bottom: 1.2rem;
	color: #000000;
	text-shadow: 1px 1px #000000;
	font-weight: bold;
}

nav { 
	font-family: 'Cinzel Decorative';

	.nav-item {
		margin-right: 5px;
	}
}

.carousel-item{
	img:hover{
		cursor: pointer;
	}
}

/* parallax "viewport" */
.parallax {
	height: calc(100vh - 100px);

  overflow-x: hidden;
  overflow-y: auto;

  perspective: 1px;
}

.parallax-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.layer1 {
  transform: translateZ(-1px);
	scale: 2;
}

.layer2 {
  transform: translateZ(-2px);
	scale: 2.5;
}

.layer3 {
  /* transform: translateZ(-1px); */
}

.parallax-layer.layer1 {
  width: 100%;
  height: 100rem;

  background-image: url('/img/bg.png');
	background-position: center;
}

.parallax-layer.layer2 {
  width: 100%;
  height: 100rem;

  background-image: url('/img/clouds.png');
	background-position: center;
	opacity: 0.9;
}

.parallax-layer.layer3 {
	top: 0;
  width: 100%;
	margin: 0px auto 20px auto;	

	section {
		padding: 75px 0px;

		&.intro {
			background-color: rgb(18, 18, 18, 0.95);

			.intro-text{
				font-size: 1.8em;
				text-align: center;
				margin: 20px 8%;
				font-family: 'Cinzel Decorative';
				font-style: oblique;
				color: #FFF;
			}

		}

		&.odd {
			background-color: rgba( 128, 128, 128, 1);
		}

		&.pair {
			background-color: rgba(255, 255, 255, 1);
		}

		&.downloads{
			background-color: transparent;
		}
		
		.wrapper {
			width: 100%;
			margin: 0 10%;
		}

	}
}


.parallax-layer.layer3 section.buffer-50 {
	margin-bottom: 50px;
}

.parallax-layer.layer3 section.buffer-100 {
	margin-bottom: 100px;
}

footer{	
	background-color: black;
	color: #FFF;
	padding: 25px 0;

	.content{
		width: 1280px;
		margin: 0 auto;
		padding: 20px 0;
	}
}

section
 {
	a {
		color: #333333;
		
		&:hover {
			color: black;
		}
	}
}
